import { useState } from 'react';

import { snowplowButtonClick } from '@analytics/snowplow/events';
import { ButtonOutlined, CheckMarkIcon } from '@pebmed/storybook-react';
import Styles from '@screens/landing-page-default/components/plans/components/column-corporate/style';
import PlanFeatureTableColumn from '@screens/landing-page-default/components/plans/components/feature-table/column/index';
import PlanFeatureTableHeader from '@screens/landing-page-default/components/plans/components/feature-table/header/index';
import { defaultTheme } from '@styles/theme';

export default function ColumnCorporate() {
  const [isMoreDetailsClickedCorporate, setIsMoreDetailsClickedCorporate] = useState(false);

  const checkMarkIcon = <CheckMarkIcon size={36} strokeColor={defaultTheme.tokens.pink[150]} />;

  return (
    <Styles.ColumnPlan>
      <Styles.PlanInfoDiv>
        <Styles.Type>Corporativo</Styles.Type>
        <Styles.Price>Preços personalizados</Styles.Price>
        <Styles.Description>
          Indicado para instituições de ensino, Associações, Hospitais e demais empresas
        </Styles.Description>
      </Styles.PlanInfoDiv>
      <Styles.DivBtn>
        <Styles.ExternalLink
          as="a"
          href="https://interactivecontent.afya.com.br/solucoes-pebmed/"
          target="_blank"
          rel="noreferrer"
        >
          <ButtonOutlined
            height="50px"
            width="118px"
            onClick={() => {
              snowplowButtonClick('corporate_offer', '/planos', null);
            }}
            data-testid="selectCorpButton"
          >
            Selecionar
          </ButtonOutlined>
        </Styles.ExternalLink>
      </Styles.DivBtn>
      <Styles.FeatureDivDesktop>
        <Styles.BlankSpaceFeature />
        <PlanFeatureTableColumn
          featureRow1="Ilimitado"
          featureRow2={checkMarkIcon}
          featureRow3={checkMarkIcon}
          featureRow4={checkMarkIcon}
          featureRow5="+ 1.400"
          featureHighlight1="+ 3.000"
          featureHighlight2="+ 180"
        />
      </Styles.FeatureDivDesktop>
      {isMoreDetailsClickedCorporate ? (
        <Styles.Div />
      ) : (
        <Styles.BtnMaisDetalhes onClick={() => setIsMoreDetailsClickedCorporate(true)}>
          Mais detalhes
        </Styles.BtnMaisDetalhes>
      )}
      {isMoreDetailsClickedCorporate ? (
        <Styles.FeatureDivMobile>
          <Styles.FeatureTxtPlanColumn>Características do plano</Styles.FeatureTxtPlanColumn>
          <Styles.GridMobile>
            <Styles.GridItem>
              <PlanFeatureTableHeader
                featureRow1="Acesso"
                featureRow2="Medicamentos"
                featureRow3="Código CID, TUSS e SUS"
                featureRow4="Fluxogramas interativos"
                featureRow5="Prescrições"
                featureHighlight1="Condutas clínicas"
                featureHighlight2="Calculadoras e escores"
              />
            </Styles.GridItem>
            <Styles.GridItem>
              <PlanFeatureTableColumn
                featureRow1="Ilimitado"
                featureRow2={checkMarkIcon}
                featureRow3={checkMarkIcon}
                featureRow4={checkMarkIcon}
                featureRow5="+ 1.400"
                featureHighlight1="+ 3.000"
                featureHighlight2="+ 180"
              />
            </Styles.GridItem>
          </Styles.GridMobile>
          <Styles.BtnReduzir onClick={() => setIsMoreDetailsClickedCorporate(false)}>Reduzir</Styles.BtnReduzir>
        </Styles.FeatureDivMobile>
      ) : (
        <Styles.BlankSpaceFeature />
      )}
    </Styles.ColumnPlan>
  );
}
